<template>
  <div class="content-filtes">
    <div class="content-filter-general-search">
      <v-skeleton-loader type="button"></v-skeleton-loader>
    </div>
    <div class="content-filter-status">
      <v-skeleton-loader type="button"></v-skeleton-loader>
    </div>
    <!-- @click="menu = true;" -->
    <div class="content-filter-date-range">
      <v-skeleton-loader type="button"></v-skeleton-loader>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.content-filtes {
  display: flex;
  width: 100%;
  margin-bottom: 15px;
}
.content-filter-general-search {
  width: 300px;
}
.content-filter-status {
  width: 300px;
  margin-left: 15px;
}
.content-filter-date-range {
  width: 300px;
  margin-left: 15px;
}

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-filtes {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }
  .content-filter-general-search {
    width: 100%;
    margin-bottom: 10px;
  }
  .content-filter-status {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 10px;
  }
  .content-filter-date-range {
    width: 100%;
    margin-left: 0px;
  }
}
/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .content-filtes {
    display: block;
    width: 100%;
    margin-bottom: 15px;
  }
  .content-filter-general-search {
    width: 100%;
    margin-bottom: 10px;
  }
  .content-filter-status {
    width: 100%;
    margin-left: 0px;
    margin-bottom: 10px;
  }
  .content-filter-date-range {
    width: 100%;
    margin-left: 0px;
  }
}
/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}
/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}
/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>