import { render, staticRenderFns } from "./SkeletonFilterSalesOrdersComponent.vue?vue&type=template&id=ee289bca&scoped=true&"
import script from "./SkeletonFilterSalesOrdersComponent.vue?vue&type=script&lang=js&"
export * from "./SkeletonFilterSalesOrdersComponent.vue?vue&type=script&lang=js&"
import style0 from "./SkeletonFilterSalesOrdersComponent.vue?vue&type=style&index=0&id=ee289bca&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ee289bca",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VSkeletonLoader})
